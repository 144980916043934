import React, { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import PropertyCard from '../../PropertyCard/PopertyCard';
import './resultSection.scss';
import resultImg1 from '../../../images/result-img-1.jpg';
import resultImg2 from '../../../images/result-img-2.jpg';
import resultImg3 from '../../../images/result-img-3.jpg';
import resultImg4 from '../../../images/result-img-4.jpg';
import resultImg5 from '../../../images/result-img-5.jpg';
import resultImg6 from '../../../images/result-img-6.jpg';
import propertyLabel from '../../../images/property-label.png';
import { isMobileOnly, isTablet, isIPad13, isIOS13 } from "react-device-detect";
const ResultSection =() => {
const propertyData = [
	{
		id: 1,
		propertyImg: resultImg1,
		totalImages: 30,
		videoUrl: '/',
		isSaved: false,
		propertyName: 'Curraghtemple, Nenagh, Tipperary,',
		propertyName2: 'E45 W562',
		propertyDetails: '5 Bed Country House For Sale',
		propertyPrice: '€795,000',
		bedroomCount: 5,
		bathroomCount: 1,
		loungeCount: 1,
		propertyArea: 97,
		propertyLabel: propertyLabel,
	},
	{
		id: 2,
		propertyImg: resultImg2,
		totalImages: 18,
		isSaved: true,
		propertyName: '12 Merton Road, Rathmines,',
		propertyName2: 'Dublin 6',
		propertyDetails: '5 Bed Semi-Detached House For Sale',
		propertyPrice: '€1,800,000',
		bedroomCount: 3,
		bathroomCount: 2,
		loungeCount: 1,
		propertyArea: 123,
		propertyLabel: propertyLabel,
	},
	{
		id: 3,
		propertyImg: resultImg3,
		totalImages: 14,
		videoUrl: '/',
		isSaved: false,
		propertyName: '13 Fernhill Road, Manor Estate,',
		propertyName2: 'Dublin 12, Dublin D12YD93',
		propertyDetails: '5 Bed Country House For Sale',
		propertyPrice: '€615,000',
		bedroomCount: 3,
		bathroomCount: 2,
		loungeCount: 1,
		propertyArea: 123,
		propertyLabel: propertyLabel,
	},
	{
		id: 4,
		propertyImg: resultImg4,
		totalImages: 28,
		videoUrl: '/',
		isSaved: false,
		propertyName: '45 Ramleh Hall, Mount Saint Annes,',
		propertyName2: ' Milltown, Dublin 6 D06RK33',
		propertyDetails: '2 Bed Apartment 81 m² / 872 ft² For Sale',
		propertyPrice: '€545,000',
		bedroomCount: 3,
		bathroomCount: 1,
		loungeCount: 1,
		propertyArea: 97,
		propertyLabel: propertyLabel,
		propertyTag: 'under offer'
	},
	{
		id: 5,
		propertyImg: resultImg5,
		totalImages: 16,
		isSaved: false,
		propertyName: '118 Glasnevin Avenue, Glasnevin,',
		propertyName2: ' Dublin 11 D11C2W6',
		propertyDetails: 'Detached House Sale Agreed',
		propertyPrice: '€825,000',
		bedroomCount: 3,
		bathroomCount: 1,
		loungeCount: 1,
		propertyArea: 97,
		propertyLabel: propertyLabel,
	},
	{
		id: 6,
		propertyImg: resultImg6,
		totalImages: 20,
		videoUrl: '/',
		isSaved: false,
		propertyName: '18 Riverside Drive, Castle Park, Rathfarnham, Dublin 14 D14C6X8',
		propertyDetails: '4 Bed Semi-Detached House Sale Agreed',
		propertyPrice: '€615,000  ',
		bedroomCount: 3,
		bathroomCount: 1,
		loungeCount: 1,
		propertyArea: 97,
		propertyLabel: propertyLabel,
		propertyTag: 'sale agreed'
	}
]


let [reviewList, setCustomerReview] = useState([]);
let intialNumberOfReview = 6;

useEffect(() => {
  if (isMobileOnly) {
	intialNumberOfReview = 2;
  } else if (isTablet || isIPad13 || isIOS13) {
	intialNumberOfReview = 4;
  }
}, []);

useEffect(() => {
  setCustomerReview(propertyData.slice(0, intialNumberOfReview));
}, []);



	return (
		<section className='result-section'>
			<Container>
				<Row>
					<Col>
						<div className="result-block">
							{reviewList.map(property => (
								<PropertyCard key={property.id} propertyData={property} />
							))}
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	)
}

export default ResultSection;
