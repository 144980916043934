import React, { Fragment } from 'react';
import Header from '../Header/Header';
import SecondaryHeader from './SecondaryHeader/SecondaryHeader';
import PropertyResultsHeader from './PropertyResultsHeader/PropertyResultsHeader';
import ResultSection from './ResultSection/ResultSection';
import Valuation from './Valuation/Valuation';
import MoreResults from './MoreResults/MoreResults';

const SearchResults = () => {
	return (
		<Fragment>
			<div className="header-wide">
				<Header />
			</div>
			<SecondaryHeader />
			<PropertyResultsHeader />
			<ResultSection />
			<Valuation />
			<MoreResults />
		</Fragment>
	)
}

export default SearchResults;
