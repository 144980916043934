import React, { Fragment } from 'react';
import SearchResults from '../../Components/SearchResults/SearchResults';

const SearchResluts = () => {
	return (
		<Fragment>
			<SearchResults />
		</Fragment>
	)
}

export default SearchResluts
