import React,{useState,useRef} from 'react';
import { Container, Row, Col,Dropdown } from 'react-bootstrap';
import './propertyResultsHeader.scss';

const PropertyResultsHeader = () => {
	const customSelectRef = useRef();
	const options = ["Most Recent", "Most Recent1", "Most Recent2"];
	const [isOpen, setIsOpen] = useState(false);
  	const [selectedOption, setSelectedOption] = useState(null);
	const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = value => () => {
    setSelectedOption(value);
    setIsOpen(!isOpen)
  };

  const checkIfClickedOutside = (event) => {
	if (isOpen && customSelectRef.current && !customSelectRef.current.contains(event.target)) {
		setIsOpen(false);
	}
}
if (typeof document !== `undefined`) {
document.addEventListener("mousedown", checkIfClickedOutside);
}

	return (
		<section className="property-results">
			<Container>
				<Row>
					<Col sm="12">
						<div className="section-header d-flex flex-wrap">
							<div className="searched-property">197 homes for sale in Balbriggan, Dublin</div>
							<div className="save-search">
								<button type='button'>
									<i className="icon-heart" />
									<span className="btn-text d-md-none">Save</span>
									<span className="btn-text d-none d-md-inline-block">Save Search</span>
								</button>
							</div>
							<div className="map-wrap">
								<button type='button'>
									<i className="icon-tag"></i>
									<span className="btn-text d-md-none">map</span>
									<span className="btn-text d-none d-md-inline-block">show on map</span>
								</button>
							</div>
							<div className="filter-wrap d-none d-md-block">
								<div className="select-option dropdown" ref={customSelectRef}>
										<div onClick={toggling} className={isOpen? 'dropdown-toggle btn is-open':'dropdown-toggle btn'}>
										{selectedOption || "Most Recent"}
										</div>
										{isOpen && (
										<div className={isOpen? 'dropdown-menu show':'dropdown-menu'}>
											<ul>
											{options.map(option => (
												<li className="dropdown-item" onClick={onOptionClicked(option)} key={Math.random()}>
												{option}
												</li>
											))}
											</ul>
										</div>
										)}
							</div>

							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	)
}

export default PropertyResultsHeader;
